<template>
  <div class="single-predict-result">
    <prediction-result v-if="formula" :formula="formula" :score="score" :category="category"
      :mode="this.$route.query.mode" class="predict-result"></prediction-result>
  </div>
</template>

<script>
import PredictResult from "./PredictionResult.vue";
import { historyApi } from "../api/index";

export default {
  props: ["resultId"],
  data() {
    return {
      score: null,
      formula: null,
      category: null,
    };
  },
  components: {
    "prediction-result": PredictResult,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      historyApi
        .getResultById(this.resultId)
        .then((res) => {
          if (res.code == 200) {
            let formulas = res.data.input_content
              .match(/'(.*?)'/g)
              .map((item) => {
                return item.replace(/'/g, "");
              });
            console.log(res.data.output_score.replace(/'/g, '"'));
            const resultData = JSON.parse(
              res.data.output_score.replace(/'/g, '"').replace(/#/g, "'")
            );
            console.log(resultData);
            this.formula = formulas[0];
            this.score = resultData[formulas[0]];
            this.category = resultData.category_list;
          } else if (res.code == 401) {
            this.$store.commit("alertMsg", {
              msg: "Please login first!",
              type: "error",
            });
            this.$router.push({
              path: "/account/sign-in",
              query: { redirect: this.$route.path },
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.single-predict-result {
  padding: 60px 0;
  min-height: 70vh;
}

.predict-result {
  width: 100%;
  overflow: hidden;
}
</style>
